<template>
    <div class="frame-5">
        <v-container>
            <notification-popup  
                :dialog="notifSuccess"
                :contentMsg="contentMsg"
                :headerMsg="$t('SuccessScanQR')"
                :nameBtnNo="$t('labelNo')"
                :nameBtnYes="$t('BacktoRecentTrip')"
                disableBtnNo="true"
                colorIcon = "success"
                typeModal="transaction"
                Icon="mdi-check-circle"
                imgSource="notification/success2.svg"
                :onHandlerNo="cancelSuccess"
                :onHandlerYes="buttonOke"/>
        
            <notification-popup 
                :dialog="notifFailed"
                imgSource="notification/failed.svg"
                :contentMsg="contentMsg"
                :headerMsg="$t('Failed')"
                :nameBtnNo="$t('labelNo')"
                :nameBtnYes="$t('Cancel')"
                colorIcon = "danger"
                typeModal="transaction"
                Icon="mdi-close-circle"
                disableBtnNo="true"
                :onHandlerYes="buttonOkeFailed"/>
                
            <div class="frame-camera">
                <div class="camera">
                <qrcode-stream @decode="onDecode" @init="onInit" />
                </div>
            </div>
            <div align="right">
                <img
                    alt=""
                    class="change-camera-img"
                    :src="require('@/assets/assetsScanQr/change-camera-img.svg')"
                />
            </div>
        </v-container>
    </div>
</template>

<script>
import NotificationPopup from "@/components/NotificationPopup.vue";
import { QrcodeStream } from 'vue-qrcode-reader'
export default {
    name: "ScanQR",
    components: { QrcodeStream, NotificationPopup },
    data() {
        return {
            notifSuccess: false,
            notifFailed: false,
            result: '',
            error: '',
            contentMsg: ""
        }
    },
    methods: {
        onDecode(result) {
            this.result = result
        },
        async onInit(promise) {
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = "ERROR: you need to grant camera access permisson"
                } else if (error.name === 'NotFoundError') {
                    this.error = "ERROR: no camera on this device"
                } else if (error.name === 'NotSupportedError') {
                    this.error = "ERROR: secure context required (HTTPS, localhost)"
                } else if (error.name === 'NotReadableError') {
                    this.error = "ERROR: is the camera already in use?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "ERROR: installed cameras are not suitable"
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "ERROR: Stream API is not supported in this browser"
                }
            }
        },
    },
    watch: {
        result: function () {
            console.log(payload)
            const payload = {
                customer_id: '',
                qr_code: this.result
            };

            this.$store
                .dispatch("payment/paygMpm", payload)
                .then((response) => {
                    if (response.status) {
                        this.notifSuccess = true
                        this.notifFailed = false
                        this.contentMsg = this.$t('Enjoyyourtrip')
                    } else {
                        let message = response.message
                        if (message === '') {
                            message = 'PleaseTryAgain'
                        }
                        this.contentMsg = this.$t(message)
                        this.notifFailed = true
                        this.notifSuccess = false
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    }
}
</script>

<style scoped>
/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
    .frame-5 {
        background-color: rgba(100, 100, 100, 1);
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .flex-wrapper-one {
        margin-bottom: 105px;
        display: flex;
        align-items: flex-start;
    }

    .back-img {
        margin-top: 4px;
        margin-right: 19px;
    }

    .scanqrcodetogetticket {
        width: 307px;
        font-family: "Roboto";
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
    }

    .camera {
        margin: auto;
        width: 70%;
    }

    .frame-camera {
        margin: auto;
        background-image: url('~@/assets/assetsScanQr/frame-camera-img.svg');

        background-position: center;

    }

    .change-camera-img {
        padding: 105px;
    }
}


/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
    .frame-5 {
        background-color: rgba(100, 100, 100, 1);
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .flex-wrapper-one {
        margin-bottom: 105px;
        display: flex;
        align-items: flex-start;
    }

    .back-img {
        margin-top: 4px;
        margin-right: 19px;
    }

    .scanqrcodetogetticket {
        width: 307px;
        font-family: "Roboto";
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
    }

    .camera {
        margin: auto;
        width: 66%;
    }

    .frame-camera {
        margin: auto;
        padding: 55px 45px;
        background-image: url('~@/assets/assetsScanQr/frame-camera-img.svg');

        background-position: center;
    }

    .change-camera-img {
        padding: 105px;
    }
}
</style>